<template>
  <svg class="logo-mobile" width="30" height="36" viewBox="0 0 44 50" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.281 11.2566L23.6789 0.458951C22.6306 -0.150949 21.3407 -0.150949 20.2924 0.458951L1.69324 11.2566C0.644904 11.8635 0 12.9856 0 14.2024V35.7947C0 37.0115 0.644904 38.1366 1.69324 38.7435L20.2924 49.5411C21.3407 50.151 22.6306 50.151 23.6789 49.5411L42.281 38.7435C43.3293 38.1366 43.9742 37.0115 43.9742 35.7947V14.2024C43.9742 12.9856 43.3293 11.8635 42.281 11.2566ZM7.67701 37.0027L4.8412 35.3269C4.35531 35.0397 4.05789 34.5157 4.05789 33.9502V15.2979C4.05789 14.7294 4.35826 14.2024 4.85003 13.9153L18.8877 5.71712C19.9479 5.09834 21.276 5.86812 21.276 7.09976V10.1937C21.276 10.771 20.9668 11.3039 20.4661 11.5852L10.8809 17.021C10.3803 17.3052 10.0711 17.8352 10.0711 18.4125V24.9645L21.1788 18.3888C21.6588 18.1046 22.2507 18.0928 22.7395 18.3592L25.3662 19.7804C26.4499 20.3666 26.4882 21.915 25.4369 22.5545L10.8368 31.4514C10.3626 31.7415 10.0711 32.2596 10.0711 32.8192V35.6259C10.0711 36.8605 8.73713 37.6274 7.67701 37.0027ZM21.3761 45.2215L17.1209 42.8085C16.6203 42.5243 16.3111 41.9944 16.3111 41.417V36.3099C16.3111 35.7444 16.6085 35.2203 17.0944 34.9331L21.3584 32.4047C21.862 32.1057 22.4863 32.1086 22.9839 32.4106L31.3853 37.4882C32.419 38.1129 32.4131 39.6199 31.3736 40.2357L22.9633 45.2097C22.4745 45.4939 21.8708 45.4998 21.3761 45.2215ZM37.5517 33.5268L30.7817 29.7046C29.6892 29.0887 29.7009 27.5048 30.8023 26.9067L33.6823 25.3376C34.1947 25.0593 34.5156 24.5175 34.5156 23.9312V18.6494C34.5156 18.0721 34.2035 17.5362 33.7029 17.2549L23.8909 11.7362C23.3874 11.4549 23.0782 10.919 23.0782 10.3417V7.1116C23.0782 5.877 24.4121 5.11018 25.4693 5.73193L39.1301 13.7643C39.6189 14.0514 39.9163 14.5755 39.9163 15.1439V32.1323C39.9193 33.3551 38.6118 34.1249 37.5517 33.5268Z"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1519_4235"
        x1="1.60908"
        y1="30.7568"
        x2="43.9771"
        y2="18.9154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#432069" />
        <stop offset="0.276" stop-color="#46216D" />
        <stop offset="0.7459" stop-color="#4E2479" />
        <stop offset="1" stop-color="#532680" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
