<template>
  <svg
    class="logo-svg"
    width="153"
    height="42"
    viewBox="0 0 153 42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="logo-text"
      d="M42.6644 27.8023H41.0768C40.6354 27.8023 40.4141 27.5809 40.4141 27.1396V14.4031C40.4141 13.9617 40.6354 13.7404 41.0768 13.7404H50.4659C53.1985 13.7404 54.765 14.7048 54.765 17.3965V18.2199C54.765 20.1079 53.9811 21.1527 52.595 21.5955C53.9811 22.0579 54.765 23.0816 54.765 24.971V27.141C54.765 27.5823 54.5437 27.8037 54.1023 27.8037H52.5147C52.0931 27.8037 51.8717 27.5823 51.8717 27.141V24.8709C51.8717 23.5046 51.1484 22.8814 49.6214 22.8814H43.3257V27.141C43.3257 27.5823 43.1043 27.8037 42.663 27.8037L42.6644 27.8023ZM51.8731 17.8773C51.8731 16.7719 51.3105 16.2896 50.0246 16.2896H43.3271V20.3279H50.0246C51.3105 20.3279 51.8731 19.8654 51.8731 18.7614V17.8773Z"
    />
    <path
      class="logo-text"
      d="M59.8086 23.2621C59.8086 24.548 60.5122 25.2516 61.7981 25.2516H70.6853C71.1069 25.2516 71.3283 25.473 71.3283 25.8946V27.1396C71.3283 27.5809 71.1069 27.8023 70.6853 27.8023H61.0339C58.3817 27.8023 56.9153 26.295 56.9153 23.6639V17.8773C56.9153 15.2448 58.3817 13.739 61.0339 13.739H70.6853C71.1069 13.739 71.3283 13.9603 71.3283 14.4017V15.6467C71.3283 16.0683 71.1069 16.2896 70.6853 16.2896H61.7981C60.5122 16.2896 59.8086 16.9932 59.8086 18.2792V19.5242H69.0779C69.4995 19.5242 69.7209 19.7456 69.7209 20.1869V21.3515C69.7209 21.7929 69.4995 22.0142 69.0779 22.0142H59.8086V23.2593V23.2621Z"
    />
    <path
      class="logo-text"
      d="M81.0361 16.2911H78.3599C77.074 16.2911 76.3704 16.9947 76.3704 18.2806V27.141C76.3704 27.5824 76.149 27.8037 75.7274 27.8037H74.1398C73.6984 27.8037 73.4771 27.5824 73.4771 27.141V17.8787C73.4771 15.2463 74.9434 13.7404 77.6154 13.7404H80.8739C82.6223 13.7404 83.8279 14.4031 84.4694 15.5889C85.0926 14.4031 86.3179 13.7404 88.0649 13.7404H91.3234C93.9954 13.7404 95.4618 15.2477 95.4618 17.8787V27.141C95.4618 27.5824 95.2404 27.8037 94.7991 27.8037H93.2114C92.7898 27.8037 92.5685 27.5824 92.5685 27.141V18.2806C92.5685 16.9947 91.8649 16.2911 90.5789 16.2911H87.9028C86.6168 16.2911 85.9132 16.9947 85.9132 18.2806V27.141C85.9132 27.5824 85.6919 27.8037 85.2703 27.8037H83.6629C83.2413 27.8037 83.0199 27.5824 83.0199 27.141V18.2806C83.0199 16.9947 82.3163 16.2911 81.0304 16.2911H81.0361Z"
    />
    <path
      class="logo-text"
      d="M97.8757 23.6639V17.8773C97.8757 15.2448 99.3421 13.739 101.994 13.739H108.953C111.625 13.739 113.091 15.2462 113.091 17.8773V23.6639C113.091 26.2964 111.625 27.8023 108.953 27.8023H101.994C99.3421 27.8023 97.8757 26.295 97.8757 23.6639ZM110.199 23.2621V18.2792C110.199 16.9932 109.496 16.2896 108.21 16.2896H102.757C101.471 16.2896 100.768 16.9932 100.768 18.2792V23.2621C100.768 24.548 101.471 25.2516 102.757 25.2516H108.21C109.496 25.2516 110.199 24.548 110.199 23.2621Z"
    />
    <path
      class="logo-text"
      d="M117.834 27.8023H116.247C115.805 27.8023 115.584 27.5809 115.584 27.1396V14.4031C115.584 13.9618 115.805 13.7404 116.247 13.7404H125.636C128.368 13.7404 129.935 14.7048 129.935 17.3965V18.22C129.935 20.1079 129.151 21.1528 127.765 21.5955C129.151 22.058 129.935 23.0816 129.935 24.971V27.141C129.935 27.5824 129.714 27.8037 129.272 27.8037H127.685C127.263 27.8037 127.042 27.5824 127.042 27.141V24.8709C127.042 23.5046 126.318 22.8814 124.791 22.8814H118.496V27.141C118.496 27.5824 118.274 27.8037 117.833 27.8037L117.834 27.8023ZM127.043 17.8773C127.043 16.7719 126.48 16.2897 125.195 16.2897H118.497V20.3279H125.195C126.48 20.3279 127.043 19.8654 127.043 18.7614V17.8773Z"
    />
    <path
      class="logo-text"
      d="M136.305 13.739H142.862C145.514 13.739 147 15.2462 147 17.8773V27.1396C147 27.5809 146.779 27.8023 146.337 27.8023H144.75C144.308 27.8023 144.087 27.5809 144.087 27.1396V23.4835H135.059V27.1396C135.059 27.5809 134.837 27.8023 134.416 27.8023H132.828C132.387 27.8023 132.166 27.5809 132.166 27.1396V17.8787C132.166 15.2462 133.632 13.7404 136.304 13.7404L136.305 13.739ZM144.087 18.2792C144.087 16.9932 143.405 16.2896 142.098 16.2896H137.047C135.761 16.2896 135.057 16.9932 135.057 18.2792V20.9314H144.086V18.2792H144.087Z"
    />
    <path
      class="logo-symbol"
      d="M33.3091 12.0762L21.2945 5.06623C20.6175 4.67096 19.784 4.67096 19.1069 5.06623L7.09379 12.0762C6.41675 12.4715 6 13.2002 6 13.9907V28.0092C6 28.7998 6.41675 29.53 7.09379 29.9238L19.1069 36.9338C19.784 37.329 20.6175 37.329 21.2945 36.9338L33.3091 29.9238C33.9861 29.53 34.4029 28.7998 34.4029 28.0092V13.9907C34.4029 13.2002 33.9861 12.4729 33.3091 12.0762ZM12.5044 26.0746V27.8957C12.5044 28.6977 11.6425 29.1965 10.9569 28.7897L9.1249 27.7016C8.81056 27.5148 8.61854 27.1756 8.61854 26.8076V14.7008C8.61854 14.3314 8.8134 13.9893 9.12917 13.8039L18.1966 8.48138C18.8808 8.07892 19.7385 8.57912 19.7385 9.37828V11.3863C19.7385 11.7614 19.5393 12.1064 19.2165 12.2904L13.0264 15.8176C12.7035 16.0016 12.5044 16.3466 12.5044 16.7217V20.9748L19.6787 16.7059C19.9888 16.5219 20.37 16.5147 20.6857 16.6858L22.3826 17.6086C23.0824 17.9895 23.1066 18.9942 22.4281 19.4096L12.998 25.1848C12.6907 25.3731 12.503 25.7095 12.503 26.0717L12.5044 26.0746ZM26.2642 30.8882L20.8308 34.118C20.5151 34.3063 20.1253 34.3091 19.8053 34.128L17.0559 32.5613C16.733 32.3773 16.5339 32.0324 16.5339 31.6572V28.3413C16.5339 27.9733 16.7259 27.6341 17.0388 27.4472L19.7939 25.8058C20.1182 25.6117 20.5222 25.6146 20.845 25.8101L26.2727 29.1059C26.9398 29.5113 26.9355 30.4901 26.2642 30.8897V30.8882ZM31.7829 25.6304C31.7829 26.4238 30.938 26.924 30.2539 26.5359L25.8802 24.0551C25.1747 23.6555 25.1832 22.6264 25.893 22.2383L27.752 21.2206C28.0834 21.0395 28.2896 20.6888 28.2896 20.3079V16.8769C28.2896 16.5018 28.0891 16.1554 27.7648 15.9714L21.4296 12.3895C21.1053 12.2056 20.9048 11.8592 20.9048 11.484V9.38691C20.9048 8.58487 21.7653 8.0861 22.4495 8.49144L31.2737 13.7076C31.588 13.893 31.7815 14.2351 31.7815 14.6031V25.6319L31.7829 25.6304Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
