<template>
  <div class="d-flex preloader-container">
    <div class="preloader">
      <span class="rot-1"></span>
      <span class="rot-2"></span>
      <span class="rot-3"></span>
      <span class="rot-4"></span>
      <span class="rot-5"></span>
      <span class="rot-6"></span>
      <span class="rot-7"></span>
      <span class="rot-8"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderComponent"
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
