var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, invalid }){return [(_vm.isGrouped)?_c('b-form-radio-group',_vm._g(_vm._b({class:{
      'input-disabled': _vm.$attrs.disabled,
      'is-invalid': invalid & errors.length
    },attrs:{"options":_vm.options},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-form-radio-group',_vm.$attrs,false),_vm.$listeners),[_vm._t("radio")],2):_c('b-form-radio',_vm._g(_vm._b({class:{
      'input-disabled': _vm.$attrs.disabled,
      'is-invalid': invalid & errors.length
    },attrs:{"name":_vm.name},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-form-radio',_vm.$attrs,false),_vm.$listeners),[_vm._t("radio")],2),_vm._l((errors),function(error,index){return _c('b-form-invalid-feedback',{key:index},[_vm._v(" "+_vm._s(error)+" ")])})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }